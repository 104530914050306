import { makeStyles } from '@material-ui/core/styles';
import {
  BLACK_TWO,
  BLUE, ERROR, SHADE_GRAY, WARM_GRAY,
} from '../../utils/colors/colors.contants';

export default makeStyles({
  outlinedRoot: {
    '& .MuiInputBase-root': {
      fontSize: '0.865rem',
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.865rem',
      color: WARM_GRAY,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.857)',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: BLUE,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: `${ERROR} !important`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: WARM_GRAY,
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${BLUE}`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: `2px solid ${ERROR}`,
    },
  },
  formOutlinedRoot: {
    border: '0',
    margin: '0',
    display: 'inline-flex',
    padding: '0',
    position: 'relative',
    minWidth: '0',
    flexDirection: 'column',
    verticalAlign: 'top',
    '&:focus-within': {
      '& label': {
        color: BLUE,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: `${ERROR} !important`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid red',
    },
    '& .MuiInputBase-input': {
      borderRadius: '2px',
      paddingLeft: '8px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: SHADE_GRAY,
      borderRadius: '2px',
      height: '50px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.75rem',
      color: WARM_GRAY,
      marginBottom: '3px',
    },

    '& .MuiInputBase-input.Mui-disabled': {
      color: WARM_GRAY,
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${BLUE}`,
    },
  },
  formStandardRoot: {
    border: '0',
    margin: '0',
    display: 'inline-flex',
    padding: '0',
    position: 'relative',
    minWidth: '0',
    flexDirection: 'column',
    verticalAlign: 'top',
    '&:focus-within': {
      '& label': {
        color: BLUE,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: `${ERROR} !important`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid red',
    },
    '& .MuiInputBase-input': {
      borderRadius: '2px',
      paddingLeft: '8px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '1rem',
      color: BLACK_TWO,
      marginBottom: '3px',
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${BLUE}`,
    },
  },
  disabledStandard: {
    '& .MuiFormLabel-root': {
      color: 'grey',
    },
    '& .MuiInput-underline.Mui-disabled::before': {
      borderBottom: '1px solid grey',
    },
  },
  loading: {
    '& .MuiCircularProgress-root': {
      color: BLUE,
    },
    '& .MuiInputBase-root ': {
      cursor: 'wait !important',
      paddingRight: '6px',
    },
    '& input': {
      cursor: 'wait !important',
    },
  },
  fullWidth: {
    width: '100%',
  },
});
