import { gateway, gatewayLogin } from '../api.service';

const { REACT_APP_API_URL } = process.env;
const { REACT_APP_LOGIN_URL } = process.env;

const LOGIN = '/autenticacao/login';

export function getParametrosResumo(credentials) {
  return gateway.get(`${REACT_APP_API_URL}/processos/acordos/bucar-resumo-pagamento-cartao/${credentials}`);
}

export function realizarAcordoDividaCartaoCredito(idProcesso, credentials, payload) {
  return gateway.post(`${REACT_APP_API_URL}/processos/pagamento/${idProcesso}/cartao-credito/${credentials}`, payload);
}

export const getAuthentitionInformation = (usernameParam, passwordParam) => (
  gatewayLogin.post(`${REACT_APP_LOGIN_URL}${LOGIN}`, {}, {
    headers: {
      usuario: usernameParam,
      senha: passwordParam,
    },
  })
);

export function imprimirComprovante(paymentId, numeroDeParcelas, valor, dataTransacao, nroAcordo, idProcesso) {
  const payload = {
    transacao: paymentId,
    numeroDeParcelas,
    valor,
    dataTransacao,
    nroAcordo,
    idProcesso,
  };
  return gateway.post(`${REACT_APP_API_URL}/processos/pagamento/cartao-credito/comprovante`, payload);
}
