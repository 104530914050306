import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
  API_GET_PARAMETROS_GERAIS, API_REALIZAR_ACORDO, AUTHENTICATE, IMPRIMIR_COMPROVANTE_ACTION, MONTA_DIALOG_ERRO_VALIDADE,
} from './negociar.constants';
import {
  montaDialogErroValidadeState,
  setActionsNegociarPageViewAction,
  setDialogPageViewAction,
  setOpenComprovanteAction,
  setParametrosResumoActions,
  setTidAction,
} from './negociar.store';
import {
  formatedDate,
  getExceptionMessage,
  isBadRequestException,
  isInternalErrorException,
  isNotFoundException,
  openPagePDFBase64,
} from '../../utils/utils';
import { GENERIC_ERROR_MSG } from '../../utils/utils.constants';
import {
  getAuthentitionInformation,
  getParametrosResumo, imprimirComprovante, realizarAcordoDividaCartaoCredito,
} from '../../services/Negociador/negociador.service';

export const montaDialogErroValidade = () => ({
  type: MONTA_DIALOG_ERRO_VALIDADE,
});

export const getParametrosGeraisAction = credentials => ({
  type: API_GET_PARAMETROS_GERAIS,
  credentials,
});

export const authenticateAction = (usernameParam, passwordParam, credentials) => ({
  type: AUTHENTICATE,
  username: usernameParam,
  password: passwordParam,
  credentials,
});

function getExpirationDate(expirationIn) {
  return new Date(new Date().getTime() + expirationIn * 1000);
}

function getPermissoes(response) {
  if (response.permissoes) {
    return response.permissoes;
  }
  return '';
}

function buildUsuario(usuario, accessToken, refreshToken) {
  if (!accessToken && !refreshToken) {
    return usuario;
  }
  return {
    ...usuario,
    accessToken,
    refreshToken,
    permissoes: getPermissoes(usuario),
    expirationDate: getExpirationDate(usuario.expirationIn),
  };
}

function* authenticateStep(response, accessToken, refreshToken) {
  const usuario = yield buildUsuario(response, accessToken, refreshToken);

  yield localStorage.setItem('userData', JSON.stringify(usuario));
  yield sessionStorage.setItem('nome', usuario.nomeUsuario);
}

export function* authenticateHandler(action) {
  const { username, password, credentials } = action;
  const response = yield getAuthentitionInformation(username, password);
  yield authenticateStep(response.data, response.headers['access-token'], response.headers['refresh-token']);
  if (credentials) {
    yield put(getParametrosGeraisAction(credentials));
  }
}

function* montaDialogErroValidadeHandler() {
  yield put(montaDialogErroValidadeState());
}

function* getParametrosGeraisHandler(actions) {
  const {
    credentials,
  } = actions;
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const { data } = yield call(getParametrosResumo, credentials);

    yield put(setParametrosResumoActions(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}

export const imprimirComprovanteAction = (paymentId, numeroDeParcelas, valor, dataTransacao, nroAcordo, idProcesso) => ({
  type: IMPRIMIR_COMPROVANTE_ACTION,
  paymentId,
  numeroDeParcelas,
  valor,
  dataTransacao,
  nroAcordo,
  idProcesso,
});

function* imprimirComprovanteHandler(actions) {
  const {
    paymentId,
    numeroDeParcelas,
    valor,
    dataTransacao,
    nroAcordo,
    idProcesso,
  } = actions;
  yield put(setActionsNegociarPageViewAction('loading', true));

  const response = yield call(imprimirComprovante, paymentId, numeroDeParcelas, valor, dataTransacao, nroAcordo, idProcesso);
  if (response.data != null) {
    yield openPagePDFBase64(response.data);
  }
  yield put(setOpenComprovanteAction(false));
  yield put(setActionsNegociarPageViewAction('loading', false));
}

export const realizarAcordoDividaAction = (idProcesso, credentials, fncSetErroCardValidation) => ({
  type: API_REALIZAR_ACORDO,
  idProcesso,
  credentials,
  fncSetErroCardValidation,
});

function* generateAndShowException(exception) {
  const exceptionMessage = getExceptionMessage(exception);
  const notFound = isNotFoundException(exception);
  const badRequest = isBadRequestException(exception);
  const internalException = isInternalErrorException(exception);

  if (notFound || badRequest || internalException) {
    const dialog = {
      infoContentText: '',
      contentText: exceptionMessage || GENERIC_ERROR_MSG,
      confirmButtonText: 'OK, ENTENDI',
      open: true,
    };
    yield put(setDialogPageViewAction(dialog));
  }
}

function buildDialog(contentText, infoContentText) {
  return {
    infoContentText,
    contentText,
    confirmButtonText: 'OK',
    open: true,
  };
}

function* generateAcordCartaoCreditoPayload() {
  try {
    const dadosCartao = yield select(store => store.dadosCartaoCredito);
    const dadosResumo = yield select(store => store.dadosResumo);
    return {
      qtdeParcelas: dadosResumo.numeroDeParcelas,
      valorParcela: parseFloat((dadosResumo.valor / dadosResumo.numeroDeParcelas).toFixed(2)),
      valorTotal: dadosResumo.valor,
      cartaoCredito: {
        numero: dadosCartao.number,
        codSeguranca: dadosCartao.cvc,
        dataVencimento: formatedDate(dadosCartao.expiry),
        nome: dadosCartao.name,
      },
      cliente: {
        nome: dadosCartao.name,
      },
    };
  } catch (exception) {
    const dialog = buildDialog(GENERIC_ERROR_MSG, '');
    yield put(setDialogPageViewAction(dialog));
  }
  return null;
}

function* realizarAcordoDividaCartaoCreditoHandler(idProcesso, credentials) {
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const payload = yield generateAcordCartaoCreditoPayload();
    const paymentId = yield call(realizarAcordoDividaCartaoCredito, idProcesso, credentials, payload);
    yield put(setTidAction(paymentId.data));
    yield put(setOpenComprovanteAction(true));
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}

function* realizarAcordoDividaHandler(actions) {
  const {
    idProcesso, credentials, fncSetErroCardValidation,
  } = actions;
  yield realizarAcordoDividaCartaoCreditoHandler(idProcesso, credentials, fncSetErroCardValidation);
}

export default function* watchNegociar() {
  yield takeLatest(API_REALIZAR_ACORDO, realizarAcordoDividaHandler);
  yield takeLatest(API_GET_PARAMETROS_GERAIS, getParametrosGeraisHandler);
  yield takeLatest(MONTA_DIALOG_ERRO_VALIDADE, montaDialogErroValidadeHandler);
  yield takeLatest(IMPRIMIR_COMPROVANTE_ACTION, imprimirComprovanteHandler);
  yield takeLatest(AUTHENTICATE, authenticateHandler);
}
