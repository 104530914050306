import ReactCreditCards from 'react-credit-cards';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useMemo } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import TodayIcon from '@material-ui/icons/Today';
import NumberFormat from 'react-number-format';
import InputField from '../Input/InputField';
import useStyles from './cartaoCredito.styles';
import { cardExpiry, formatExpiryToValidateDate } from './cartaoCreditoValidator';
import { setDadosCartaoCreditoAction } from '../../store/negociar/negociar.store';
import { useNegociarDispatch } from '../../store/negociar/NegociarProvider';
import { getRequiredLabel } from '../../utils/utils';


function CartaoCredito({
  dadosCartao,
  errosFormCartao,
  idStatusAcordo,
  focus,
  linkExpirado,
  acordoPago,
  onBlurCvvHandler,
  onFocusHandler,
}) {
  const dispatch = useNegociarDispatch();
  const styles = useStyles();

  const onChangeHandler = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setDadosCartaoCreditoAction(name, value));
  }, [dispatch]);

  const onChangeNumberHandler = useCallback((e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    dispatch(setDadosCartaoCreditoAction(name, numericValue));
  }, [dispatch]);

  const onBlurExpiryHandler = useCallback(() => {
    const validExpiryDate = formatExpiryToValidateDate(dadosCartao.expiry);
    dispatch(setDadosCartaoCreditoAction('validExpiry', validExpiryDate));
  }, [dispatch, dadosCartao]);

  const disabledInputs = useMemo(() => linkExpirado || idStatusAcordo === 2 || acordoPago, [linkExpirado, idStatusAcordo, acordoPago]);

  return (
    <Grid container item justify="center" alignItems="center" alignContent="center" lg={12} md={12} sm={12}>
      <div id="PaymentForm" style={{ marginRight: '160px' }}>
        <ReactCreditCards
          preview
          number={dadosCartao.number}
          expiry={dadosCartao.expiry}
          name={dadosCartao.name}
          cvc={dadosCartao.cvc}
          focused={focus}
          placeholders={{ name: 'Nome Completo' }}
          locale={{ valid: 'Validade' }}
        />
      </div>
      <div>
        <form style={{ marginTop: '20px' }}>
          <Grid container item lg={12} md={12} sm={12} className={styles.gridFieldsForm}>
            <InputField
              disabled={disabledInputs}
              isForm
              fullWidth
              showToolTip={false}
              type="text"
              name="name"
              maxLength={18}
              value={dadosCartao.name}
              error={errosFormCartao.name.error}
              errorMessage={errosFormCartao.name.message}
              label={getRequiredLabel('Nome Impresso no Cartão')}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              variant="standard"
              icon={<PersonIcon style={{ color: disabledInputs ? 'grey' : '#4A4E69' }} />}
            />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} className={styles.gridFieldsForm}>
            <InputField
              disabled={disabledInputs}
              isForm
              fullWidth
              showToolTip={false}
              maxLength={16}
              name="number"
              value={dadosCartao.number}
              error={errosFormCartao.number.error}
              errorMessage={errosFormCartao.number.message}
              label={getRequiredLabel('Número do Cartão')}
              onChange={onChangeNumberHandler}
              onFocus={onFocusHandler}
              variant="standard"
              icon={<CreditCardIcon style={{ color: disabledInputs ? 'grey' : '#4A4E69' }} />}
            />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} spacing={2} className={styles.gridValidadeCvvForm}>
            <Grid container item lg={6}>
              <NumberFormat
                disabled={disabledInputs}
                isForm
                fullWidth
                name="expiry"
                showToolTip={false}
                value={dadosCartao.expiry}
                error={errosFormCartao.expiry.error}
                errorMessage={dadosCartao.expiry ? errosFormCartao.expiry.message : 'Campo Obrigatório'}
                label={getRequiredLabel('Validade')}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurExpiryHandler}
                format={cardExpiry}
                customInput={InputField}
                variant="standard"
                icon={<TodayIcon style={{ color: disabledInputs ? 'grey' : '#4A4E69' }} />}
              />
            </Grid>
            <Grid container item lg={6}>
              <InputField
                disabled={disabledInputs}
                isForm
                fullWidth
                name="cvc"
                showToolTip={false}
                maxLength={3}
                value={dadosCartao.cvc}
                error={errosFormCartao.cvc.error}
                errorMessage={errosFormCartao.cvc.message}
                label={getRequiredLabel('Código de Segurança (CVV)')}
                onChange={onChangeNumberHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurCvvHandler}
                variant="standard"
                icon={<LockIcon style={{ color: disabledInputs ? 'grey' : '#4A4E69' }} />}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Grid>
  );
}

export default CartaoCredito;
