import { getAuthDataFromSession } from '../../utils/utils';
import {
  SET_DIALOG_PAGE_VIEW,
  SET_PAGE_VIEW_ACTIONS,
  SET_DADOS_CARTAO_CREDITO, SET_PARAMETRO_RESUMO, MONTA_DIALOG_ERRO_VALIDADE_STATE, SET_OPEN_COMPROVANTE_ACTION, SET_PAYMENT_ID_ACTION,
} from './negociar.constants';


export const setTidAction = value => ({
  type: SET_PAYMENT_ID_ACTION,
  value,
});

function setTidHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    paymentId: value,
  };
}

export const setOpenComprovanteAction = value => ({
  type: SET_OPEN_COMPROVANTE_ACTION,
  value,
});

function setOpenComprovanteHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    openComprovante: value,
  };
}

export const setParametrosResumoActions = parametrosResumo => ({
  type: SET_PARAMETRO_RESUMO,
  parametrosResumo,
});

function setParametrosResumoHandler(states, actions) {
  const { parametrosResumo } = actions;
  return {
    ...states,
    dadosResumo: {
      ...states.dadosResumo,
      credor: parametrosResumo.credor,
      seusDados: parametrosResumo.seusDados,
      processo: parametrosResumo.processo,
      acordo: parametrosResumo.acordo,
      valor: parametrosResumo.valor,
      numeroDeParcelas: parametrosResumo.numeroDeParcelas,
      dataValidade: parametrosResumo.dataValidade,
      idStatusAcordo: parametrosResumo.idStatusAcordo,
      dataPagamentoSite: parametrosResumo.dataPagamentoSite,
    },
  };
}

function buildOpcoesDePagamento() {
  return {
    qtdeParcelas: 1,
    valorParcela: 0,
    valorTotal: 0,
    textoComentario: '',
  };
}

function buildActionsPageView() {
  return {
    loading: false,
    dialog: {
      contentText: '',
      infoContentText: '',
      confirmButtonText: '',
      open: false,
      onCallbackhandler: null,
    },
  };
}
function buildDadosCartaoCredito() {
  return {
    number: '',
    expiry: '',
    name: '',
    cvc: '',
    validExpiry: '',
  };
}

function buildSearchCriteria() {
  return {
    idProcesso: '',
  };
}

function buildDadosResumo() {
  return {
    credor: '',
    seusDados: '',
    processo: 0,
    acordo: 0,
    valor: 0,
    numeroDeParcelas: 0,
    dataValidade: null,
    dataPagamentoSite: null,
    idStatusAcordo: 0,
  };
}

function buildInitialStates() {
  return {
    opcoesPagamento: [buildOpcoesDePagamento()],
    opcaoPagamentoSelected: buildOpcoesDePagamento(),
    dadosCartaoCredito: buildDadosCartaoCredito(),
    actionsPageView: buildActionsPageView(),
    searchCriteria: buildSearchCriteria(),
    dadosResumo: buildDadosResumo(),
    isAuthenticated: getAuthDataFromSession(),
    errorMessage: '',
    isInfoNaoEncontrada: true,
    isPrazoExpirado: false,
    expiredIn: '',
    openComprovante: false,
    paymentId: '',
  };
}

export const setDialogPageViewAction = dialog => ({
  type: SET_DIALOG_PAGE_VIEW,
  dialog,
});

export const setDadosCartaoCreditoAction = (name, value) => ({
  type: SET_DADOS_CARTAO_CREDITO,
  name,
  value,
});

export const setActionsNegociarPageViewAction = (name, value) => ({
  type: SET_PAGE_VIEW_ACTIONS,
  name,
  value,
});

export const montaDialogErroValidadeState = () => ({
  type: MONTA_DIALOG_ERRO_VALIDADE_STATE,
});

function montaDialogErroValidadeStateHandler(states) {
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      dialog: {
        ...states.dialog,
        infoContentText: '',
        contentText: 'Este link expirou e não pode ser utilizado.                     Entre em contato com a ABE para fazer um novo acordo.',
        confirmButtonText: 'OK, ENTENDI',
        open: true,
      },
    },
  };
}

function setActionsNegociarPageViewHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      [name]: value,
    },
  };
}

function setDadosCartaoCreditoHandler(states, action) {
  const { name, value } = action;
  return {
    ...states,
    dadosCartaoCredito: {
      ...states.dadosCartaoCredito,
      [name]: value,
    },
  };
}

function setDialogPageViewHandler(states, actions) {
  const {
    contentText,
    infoContentText,
    confirmButtonText,
    open,
  } = actions.dialog;

  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      dialog: {
        ...states.dialog,
        contentText,
        infoContentText,
        confirmButtonText,
        open,
      },
    },
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_PAGE_VIEW_ACTIONS:
      return setActionsNegociarPageViewHandler(states, actions);
    case SET_DADOS_CARTAO_CREDITO:
      return setDadosCartaoCreditoHandler(states, actions);
    case SET_DIALOG_PAGE_VIEW:
      return setDialogPageViewHandler(states, actions);
    case SET_PARAMETRO_RESUMO:
      return setParametrosResumoHandler(states, actions);
    case MONTA_DIALOG_ERRO_VALIDADE_STATE:
      return montaDialogErroValidadeStateHandler(states, actions);
    case SET_OPEN_COMPROVANTE_ACTION:
      return setOpenComprovanteHandler(states, actions);
    case SET_PAYMENT_ID_ACTION:
      return setTidHandler(states, actions);
    default:
      return states;
  }
};
