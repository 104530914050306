import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  BLACK_TWO, PURE_GRAY, VERY_LIGHT_GRAY, WARM_GRAY, WHITE,
} from '../../utils/colors/colors.contants';

export default makeStyles({
  dialog__actions: {
    justifyContent: 'center',
    marginTop: '11.5px',
    marginBottom: '43.5px',
  },
  dialog__textContainer: {
    textAlign: 'center',
    paddingBottom: '66px',
    paddingTop: '27px',
    fontSize: '21px',
    fontWeight: 'bold',
  },
  dialog__item: {
    textAlign: 'left',
    paddingBottom: '21px',
    paddingLeft: '32px',
    fontSize: '19px',
  },
  dialog__subtitle_icon: {
    display: 'flex',
    position: 'relative',
    alignItems: 'end',
  },
  dialog__subtitle: {
    height: '23px',
    margin: '0 0 47px 32px',
    fontSize: '20px',
    textAlign: 'left',
  },
  dialog__root: {
    '& .MuiDialog-paperWidthSm': {
      width: '458px',
      maxWidth: '62.5rem',
    },
    '& .MuiDialogContent-root:first-child': {
      padding: '2.1875rem 5.75rem 2.1875rem 5.75rem',
    },
  },
  gridContainer: {
    boxShadow: ' 0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: WHITE,
    padding: '26px',
    borderRadius: '10px',
    width: '100%',
    marginTop: '8px',
  },
  gridContainerTitle: {
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: BLACK_TWO,
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  gridContainerPagamentoMobile: {
    '& p': {
      fontSize: '1rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center !important',
    },
    '@media screen and (max-width: 1000px)': {
      display: 'flex',
      justifyContent: 'center !important',
    },
  },
  gridContainerProsseguirLabel: {
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& p': {
      fontSize: '1rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
  },
  gridContainerPassoUI: {
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
      '& div': {
        marginRight: '0 !important',
      },
    },
    '& p': {
      marginTop: '28px',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
        marginTop: '0 !important',
      },
      '@media screen and (max-width: 1000px)': {
        marginTop: '0 !important',
      },
    },
    '@media screen and (max-width: 1000px)': {
      display: 'flex',
      justifyContent: 'center !important',
      '& div': {
        marginRight: '0 !important',
      },
    },
  },
  containerImprimirBoletoTitle: {
    margin: '8px',
  },
  containerDadosCartaoTitle: {
    margin: '8px',
    '& h3': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: BLACK_TWO,
      textTransform: 'upperCase',
    },
    '& p': {
      color: WARM_GRAY,
    },
  },
  Footer: {
    width: '692px',
    height: '26px',
    fontSize: '22px',
    textAlign: 'left',
  },
  Footer1: {
    fontWeight: 'bold',
    color: 'red',
  },
  containerDadosDividaTitle: {
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: BLACK_TWO,
    },
    '& p': {
      color: WARM_GRAY,
    },
  },
  containerValorSerPago: {
    '& p': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: BLACK_TWO,
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
  },
  containerValorTotalValor: {
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: BLACK_TWO,
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  conatinerDivider: {
    borderTop: `dotted 1px ${BLACK_TWO}`,
    paddingTop: '10px',
  },
  container: {
    padding: '20px 0px 0px 0px',
  },
  containerLogoCliente: {
    padding: '23px 15px',
    '& img': {
      maxWidth: '360px',
      maxHeight: '140px',
    },
  },
  contaienrJustifyCenterInMobile: {
    '& p': {
      fontSize: '1rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  arrowButtonJustifyInMobile: {
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  containerOpcaPagamentoSelecionadaCartaoCredito: {
    margin: '0px 0px 16px 0px',
    backgroundColor: VERY_LIGHT_GRAY,
    boxShadow: `0 3px 6px 0 ${PURE_GRAY}`,
    padding: '21px 13px',
    borderRadius: '25px',
  },
  containerOpcaPagamentoSelecionadaCartaoCreditoTitle: {
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& p': {
      fontSize: '1.375rem',
      fontWeight: 'bold',
      marginLeft: '13px',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  containerOpcaPagamentoSelecionadaCartaoCreditoSubTitle: {
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  containerOpcaPagamentoSelecionadaCartaoCreditoValor: {
    '& p': {
      fontSize: '1rem',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
  },
});
