import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Negociar from '../components/Negociar/Negociar';
import NegociarProvider from '../store/negociar/NegociarProvider';

function RoutesSettings() {
  const negociarComponent = () => (
    <NegociarProvider>
      <Negociar />
    </NegociarProvider>
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth/:credentials" component={negociarComponent} />
      </Switch>
    </BrowserRouter>
  );
}

export default RoutesSettings;
