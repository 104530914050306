import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Typography from '@material-ui/core/Typography';
import useStyles from './dialogUI.styles';
import ButtonUI from '../Button/ButtonUI';
import PaperUI from '../Paper/PaperUI';


function DialogUI({
  contentText = '',
  confirmButtonText = 'OK',
  open = false,
  onCloseHandler,
}) {
  const styles = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.dialog__root}
      >
        <PaperUI>
          <div className={styles.dialog__textContainer}>
            <Typography component="p">

              {contentText}
            </Typography>
          </div>
          <DialogActions className={styles.dialog__actions}>
            <ButtonUI label={confirmButtonText} variant="dialog" onClick={onCloseHandler} />
          </DialogActions>
        </PaperUI>
      </Dialog>
    </div>
  );
}

export default DialogUI;
