import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Card, Dialog, DialogActions, Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import EmailIcon from '@material-ui/icons/Email';
import { ReceiptSharp } from '@material-ui/icons';
import Header from '../Header/Header';
import ButtonUI from '../UI/Button/ButtonUI';
import useStyles from './Negociar.styles';
import DialogUI from '../UI/DialogUI/DialogUI';
import {
  convertDate,
  convertDateTime,
  formatMoney,
} from '../../utils/utils';
import {
  authenticateAction,
  getParametrosGeraisAction, imprimirComprovanteAction, montaDialogErroValidade, realizarAcordoDividaAction,
} from '../../store/negociar/negociar.saga';
import { schema } from '../CreditCard/cartaoCreditoValidator';
import CartaoCredito from '../CreditCard/CartaoCredito';
import { useNegociarDispatch, useNegociarSelector } from '../../store/negociar/NegociarProvider';
import BodyContainer from '../UI/Background/BodyContainer';
import { setDialogPageViewAction } from '../../store/negociar/negociar.store';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';

const errorCartaoInitialState = {
  number: {
    error: false,
    message: '',
  },
  name: {
    error: false,
    message: '',
  },
  expiry: {
    error: false,
    message: '',
  },
  cvc: {
    error: false,
    message: '',
  },
};

function Negociar() {
  const dispatch = useNegociarDispatch();
  const styles = useStyles();

  const [focusCartao, setFocusCartao] = useState('');
  const [erros, setErros] = useState(errorCartaoInitialState);
  const [hasErrors, setHasErrors] = useState(false);
  const [linkExpirado, setLinkExpirado] = useState(false);
  const [dataTransacao, setDataTransacao] = useState(new Date());

  const { credentials } = useParams();

  const idProcesso = useNegociarSelector(states => states.dadosResumo.processo);
  const dadosCartao = useNegociarSelector(states => states.dadosCartaoCredito);
  const dadosResumo = useNegociarSelector(states => states.dadosResumo);
  const dialog = useNegociarSelector(states => states.actionsPageView.dialog);
  const openComprovante = useNegociarSelector(states => states.openComprovante);
  const paymentId = useNegociarSelector(states => states.paymentId);

  const onCloseDialogHandler = useCallback(() => {
    dispatch(setDialogPageViewAction({ open: false }));
  }, [dispatch]);

  const onFocusCartaoHandler = useCallback((event) => {
    const { name } = event.target;
    setErros({
      ...erros,
      [name]: {
        error: false,
      },
    });
    setFocusCartao(name);
  }, [setFocusCartao, erros, setErros]);

  const onSetErrorInCardValidator = useCallback((name, isError, message) => {
    setErros({
      ...erros,
      [name]: {
        error: isError,
        message,
      },
    });
  }, [setErros, erros]);

  const onBlurCvvHandler = useCallback(() => {
    setFocusCartao('');
  }, [setFocusCartao]);

  const onImprimirComprovante = useCallback(() => {
    dispatch(imprimirComprovanteAction(paymentId, dadosResumo.numeroDeParcelas, dadosResumo.valor, convertDateTime(dataTransacao).split(' ')[0], dadosResumo.acordo, idProcesso));
  }, [dispatch, dadosResumo.numeroDeParcelas, dadosResumo.valor, dataTransacao, paymentId, dadosResumo.acordo, idProcesso]);

  const onCloseComprovanteHandler = useCallback(() => {
    onImprimirComprovante();
    dispatch(getParametrosGeraisAction(credentials));
  }, [dispatch, onImprimirComprovante, credentials]);

  const onFinalizarAcordoCartaoCredito = useCallback(async () => {
    setHasErrors(false);
    const schemaToValidate = {
      number: dadosCartao.number,
      name: dadosCartao.name,
      expiry: dadosCartao.validExpiry,
      cvc: dadosCartao.cvc,
    };
    try {
      await schema.validate(schemaToValidate, { abortEarly: false });
      if (!hasErrors) {
        dispatch(realizarAcordoDividaAction(idProcesso, credentials, onSetErrorInCardValidator));
        setDataTransacao(convertDateTime(new Date()).split(' ')[0]);
      }
    } catch (e) {
      setHasErrors(true);
      e.inner.forEach((err) => {
        erros[err.path].error = true;
        erros[err.path].message = err.message;
      });
      setErros({ ...erros });
    }
  }, [setErros, erros, dadosCartao, dispatch, idProcesso, hasErrors, onSetErrorInCardValidator, credentials]);

  const formatValue = (value) => {
    const money = formatMoney(value);
    return ` ${money}`;
  };

  useEffect(() => {
    dispatch(authenticateAction('<Sistema>', 'Maria', credentials));
  }, [credentials, dispatch]);

  useEffect(() => {
    if (dadosResumo?.idStatusAcordo !== 2 && !linkExpirado && !dialog.open && (new Date(`${dadosResumo.dataValidade}T23:59:55`) < new Date() || dadosResumo.idStatusAcordo !== 0)) {
      dispatch(montaDialogErroValidade());
      setLinkExpirado(true);
    }
  }, [linkExpirado, dispatch, dadosResumo, setLinkExpirado, dialog.open]);

  const getResumoDividaComponent = () => (
    <>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineBottom}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Credor: </span>
            {dadosResumo.credor}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineSpacing}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Seus Dados: </span>
            {dadosResumo.seusDados}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineTop}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Processo: </span>
            {dadosResumo.processo}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineTop}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Acordo: </span>
            {dadosResumo.acordo}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineTop}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Valor: </span>
            {formatValue(dadosResumo.valor)}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="left" style={{ marginBottom: '10px' }}>
        <div className={styles.lineTop}>
          <Typography component="p">
            <span style={{ fontWeight: 'bold' }}>Número de Parcelas: </span>
            {dadosResumo.numeroDeParcelas}
          </Typography>
        </div>
      </Grid>
    </>
  );

  const botaoFinalizarAcordo = () => (<ButtonUI disabled={linkExpirado} onClick={onFinalizarAcordoCartaoCredito} label="CONFIRMAR PAGAMENTO" variant="primary" />);
  const botaoImprimirComprovante = () => (<ButtonUI disabled={linkExpirado} onClick={onImprimirComprovante} label="IMPRIMIR COMPROVANTE" variant="primary" />);

  const getComponentCartao = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.containerDadosCartaoTitle}>
        <Typography component="h3">
          Dados do Cartão
        </Typography>
        <Typography component="p">
          Preencha corretamente os campos abaixo com os dados do seu cartão para pagamento.
        </Typography>
      </Grid>
      <CartaoCredito
        dadosCartao={dadosCartao}
        errosFormCartao={erros}
        idStatusAcordo={dadosResumo?.idStatusAcordo}
        focus={focusCartao}
        linkExpirado={linkExpirado}
        acordoPago={!!dadosResumo?.dataPagamentoSite}
        onBlurCvvHandler={onBlurCvvHandler}
        onFocusHandler={onFocusCartaoHandler}
      />
      <Grid container spacing={1}>
        <Grid
          container
          item
          alignItems="center"
          justify="center"
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={styles.gridContainerPagamentoMobile}
        >
          { dadosResumo?.dataPagamentoSite // Quitado
            ? botaoImprimirComprovante()
            : botaoFinalizarAcordo()
          }
        </Grid>
      </Grid>
    </>
  );

  function Footer() {
    return (
      <span className={styles.Footer}>
        <span className={styles.Footer1}>IMPORTANTE: </span>
        Validade deste Link:
        {' '}
        <span style={{ fontWeight: 'bold' }}>{convertDate(dadosResumo.dataValidade)}</span>
      </span>

    );
  }

  const dialogRecibo = () => (
    <div>
      <Dialog
        open={openComprovante}
        onClose={() => { onCloseComprovanteHandler(); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.dialog__root}
      >
        <div className={styles.dialog__textContainer}>
          Pagamento realizado com sucesso!
        </div>
        <div className={styles.dialog__subtitle}>
          <div className={styles.dialog__subtitle_icon}>
            <ReceiptSharp style={{ marginRight: '12px', fontSize: '32px' }} />
            Comprovante de Transação
          </div>
        </div>
        <Grid container item lg={12} alignItems="left">
          <div className={styles.dialog__item}>
            <Typography component="p">
              <span style={{ fontWeight: 'bold' }}>Transação: </span>
              {paymentId}
            </Typography>
          </div>
        </Grid>
        <Grid container item lg={12} alignItems="left">
          <div className={styles.dialog__item}>
            <Typography component="p">
              <span style={{ fontWeight: 'bold' }}>Data: </span>
              {convertDateTime(dataTransacao).split(' ')[0]}
            </Typography>
          </div>
        </Grid>
        <Grid container item lg={12} alignItems="left">
          <div className={styles.dialog__item}>
            <Typography component="p">
              <span style={{ fontWeight: 'bold' }}>Parcelamento: </span>
              {dadosResumo.numeroDeParcelas}
            </Typography>
          </div>
        </Grid>
        <Grid container item lg={12} alignItems="left">
          <div className={styles.dialog__item}>
            <Typography component="p">
              <span style={{ fontWeight: 'bold' }}>Valor: </span>
              {formatValue(dadosResumo.valor)}
            </Typography>
          </div>
        </Grid>
        <DialogActions className={styles.dialog__actions}>
          <ButtonUI label="Salvar em pdf" variant="dialog_save" icon={<EmailIcon />} onClick={onCloseComprovanteHandler} />
        </DialogActions>
      </Dialog>

    </div>
  );

  return (
    <>
      <DialogUI
        contentText={dialog.contentText}
        infoContentText={dialog.infoContentText}
        confirmButtonText={dialog.confirmButtonText}
        open={dialog.open}
        onCloseHandler={() => onCloseDialogHandler()}
      />
      {dialogRecibo()}
      <Header />
      <BodyContainer>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.containerDadosDividaTitle}>
            <Typography component="h3">
              Informações para Pagamento:
            </Typography>
          </Grid>

          <Card className={styles.gridContainer}>
            {getResumoDividaComponent()}
          </Card>
          <Card className={styles.gridContainer}>
            {getComponentCartao()}
          </Card>
        </Grid>
      </BodyContainer>
      <div style={{ paddingTop: '20px', backgroundColor: '#DFDFDE' }}>
        <Card style={{ padding: '32px 0 32.1px 26px' }}>
          {Footer()}
        </Card>
      </div>
    </>
  );
}

export default withTokenInterceptor(Negociar);
