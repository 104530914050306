import React from 'react';
import Grid from '@material-ui/core/Grid';

import logoGrejo from '../../assets/Logos/logo-grejo.png';
import useStyles from './header.styles';
import HeaderContainer from '../UI/Background/HeaderConainer';


function Header() {
  const styles = useStyles();

  return (
    <HeaderContainer>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid
          direction="row"
          alignItems="center"
          justify="flex-start"
          container
          item
          lg={6}
          md={6}
          sm={8}
          xs={8}
          spacing={2}
        >
          <Grid item>
            <img
              className={styles.headerLogoGrejo}
              src={logoGrejo}
              alt="Grejo"
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}

export default Header;
