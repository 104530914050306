import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  container: {
    backgroundColor: WHITE,
    boxShadow: '0px 2.5px 5px 0px grey',
    overflow: 'hidden',
    marginBottom: '15px',
    boxSizing: 'border-box',
  },
  children: {
    maxWidth: '1280px',
    width: '100%',
    vw: '100%',
  },
});
