export const API_REALIZAR_ACORDO = '@negociar/REALIZAR_ACORDO';
export const API_GET_PARAMETROS_GERAIS = '@negociar/API_GET_PARAMETROS_GERAIS';
export const MONTA_DIALOG_ERRO_VALIDADE = '@negociar/MONTA_DIALOG_ERRO_VALIDADE';
export const IMPRIMIR_COMPROVANTE_ACTION = '@negociar/IMPRIMIR_COMPROVANTE_ACTION';
export const AUTHENTICATE = 'AUTHENTICATE';

export const SET_OPCOES_PAGAMENTO = '@negociar/SET_OPCOES_PAGAMENTO';
export const SET_PAGE_VIEW_ACTIONS = '@negociar/SET_PAGE_VIEW_ACTIONS';
export const SET_DIALOG_PAGE_VIEW = '@negociar/SET_DIALOG_PAGE_VIEW';
export const SET_DADOS_CARTAO_CREDITO = '@negociar/SET_DADOS_CARTAO_CREDITO';
export const SET_GLOBAL_VALUES = '@negociar/SET_GLOBAL_VALUES';
export const SET_PARAMETRO_RESUMO = '@negociar/SET_PARAMETRO_RESUMO';
export const MONTA_DIALOG_ERRO_VALIDADE_STATE = '@negociar/MONTA_DIALOG_ERRO_VALIDADE_STATE';
export const SET_OPEN_COMPROVANTE_ACTION = '@negociar/SET_OPEN_COMPROVANTE_ACTION';
export const SET_PAYMENT_ID_ACTION = '@negociar/SET_PAYMENT_ID_ACTION';
export const START_AUTHENTICATION = '@negociar/START_AUTHENTICATION';
