import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';

export function getRequiredLabel(label, showRequired) {
  return showRequired ? `${label}*` : label;
}

export function convertDate(dateTextPlain) {
  if (!dateTextPlain || dateTextPlain === '') {
    return dateTextPlain;
  }
  const splitedDate = dateTextPlain.split('-');
  return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
}

export function convertDateTime(dateTextPlain) {
  if (!dateTextPlain || dateTextPlain === '') {
    return dateTextPlain;
  }

  const splitedDate = dateTextPlain.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(', ');
  return `${splitedDate[0]} ${splitedDate[1]}`;
}

function incrmenteDateInSeconds(seconds = 900) {
  moment().add(seconds, 's');
}

export function replaceTo(history, path) {
  history.push(path);
}

export function convertDataAuthToObject(data) {
  return {
    accessToken: data.access_token,
    tokenType: data.token_type,
    refreshToken: data.refres_token,
    expiresIn: incrmenteDateInSeconds(data.expires_in),
    scope: data.scope,
    numeroControleAcordoSeguro: data.numeroControleAcordoSeguro,
    authorities: data.authorities,
    jti: data.jti,
  };
}

export const getAuthDataFromSession = () => JSON.parse(sessionStorage.getItem('authData'));

export const getItemFromSession = itemName => sessionStorage.getItem(itemName);

export const clearSession = () => sessionStorage.clear();

export const saveAuthDataInSession = (data) => {
  sessionStorage.setItem('authData', JSON.stringify(convertDataAuthToObject(data)));
};

export const saveImageSessionStorage = (imgData) => {
  sessionStorage.setItem('imgData', JSON.stringify(imgData));
};

export const saveObjectSessionStorage = (itemName, itemValue) => {
  sessionStorage.setItem(itemName, JSON.stringify(itemValue));
};


export const getImageSessionStorageIfExists = () => {
  const imgData = sessionStorage.getItem('imgData');
  return imgData || null;
};

export const getErrorMessage = (exception) => {
  if (exception.response) {
    return exception.response.data.message;
  }
  return 'OCORREU UM ERRO INESPERADO.';
};

export const getToken = () => {
  const authData = getAuthDataFromSession();
  if (authData) {
    const { tokenType, accessToken } = authData;
    return `${tokenType} ${accessToken}`;
  }
  return '';
};

export const getHeaderToken = () => {
  const token = getToken();
  if (token) {
    return {
      headers: {
        Authorization: token,
      },
    };
  }
  return '';
};

export const getNumeroControle = () => {
  const authData = getAuthDataFromSession();
  if (authData) {
    const { numeroControleAcordoSeguro } = authData;
    return numeroControleAcordoSeguro;
  }
  return null;
};

export const formatDate = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return '';
};

export const formatMoney = (value) => {
  if (value || value === 0) {
    return Number(String(value))
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
  }
  return value;
};

export function isInformacoesNaoEncontradas(message) {
  return message === 'Informações não encontradas';
}

export function isPrazoExpiradoMessage(message) {
  return message === 'Não foi possível acessar as informações. O prazo para acesso expirou.';
}

export function getRandomNumber() {
  const dateNow = new Date().getUTCMilliseconds();
  return dateNow.toString()[0];
}

export function makeQueryParams(params) {
  const keys = Object.keys(params);
  return keys
    .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
}

export function formataCep(value) {
  return (value) ? `${value.slice(0, 5)}-${value.slice(5)}` : '';
}

export function fromataTelefone(ddd, telefone) {
  let telefoneFormat = '';
  let dddFormat = '';
  if (telefone) {
    if (telefone.length === 8) {
      telefoneFormat = `${telefone.slice(0, 4)}-${telefone.slice(4)}`;
    } else {
      telefoneFormat = `${telefone.slice(0, 5)}-${telefone.slice(5)}`;
    }
  }
  if (ddd) {
    dddFormat = `(${ddd})`;
  }

  return `${dddFormat} ${telefoneFormat}`;
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function createPDFFileAndOpen(data) {
  const file = new Blob(
    [data],
    { type: 'application/pdf' },
  );
  const fileURL = URL.createObjectURL(file);

  if (isSafari()) {
    window.location = fileURL;
  } else {
    window.open(fileURL, '_blank');
  }
}

export function generateUniqId() {
  return uuidv1();
}

export function isInternalErrorException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 500;
}

export function isBadRequestException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 400;
}

export function isNotFoundException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 404;
}

export function getExceptionMessage(exception) {
  if (exception
    && exception.response
    && exception.response.data
    && exception.response.data.message
  ) {
    return exception.response.data.message;
  }
  return '';
}

export function formatedDate(date) {
  const yearNow = new Date().getFullYear().toString().substring(0, 2);
  const explitDate = date.split('/');
  return `${explitDate[0]}/${yearNow}${explitDate[1]}`;
}

export function getExceptionInNumberCard(exception) {
  const { response } = exception;
  if (response && response.data) {
    const { validations } = response.data;
    return validations[0].message;
  }
  return null;
}

export function decoderFormaPagamento(formaPagamento) {
  switch (+formaPagamento) {
    case 1: return 'P';
    case 2: return 'B';
    case 3: return 'C';
    default: return '';
  }
}

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function openPagePDFBase64(data) {
  const relatorio = b64toBlob(data, 'application/pdf');
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}
